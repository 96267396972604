/* -----------------------------------------------------------------------------
 *
 * Typography: Headings 1-6.
 *
 * ----------------------------------------------------------------------------- */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  font-weight: 700;

  a {
    color: inherit;
    text-decoration: none;
  }

  small {
    line-height: 1;
  }
}

h1,
.h1 {
  margin-bottom: 20px;
  padding-top: 16px;
  font-family: var( --font-bandwidth );
  font-size: var( --h1-font-size );
  font-weight: 400;
  line-height: var( --h1-line-height );
}

.h1--pricepoint {
  padding-top: 0;
  font-size: var( --h1-pricepoint-font-size );
  line-height: var( --h1-pricepoint-line-height );
}

h2,
.h2 {
  margin-bottom: 20px;
  padding-top: 16px;
  font-size: var( --h2-font-size );
  line-height: var( --h2-line-height );
}

h3,
.h3 {
  margin-bottom: 16px;
  padding-top: 16px;
  font-size: var( --h3-font-size );
  line-height: var( --h3-line-height );
}

h4,
.h4 {
  margin-bottom: 16px;
  padding-top: 8px;
  font-size: var( --h4-font-size );
  line-height: var( --h4-line-height );
}

h5,
.h5 {
  margin-bottom: 12px;
  padding-top: 8px;
  font-size: var( --h5-font-size );
  line-height: var( --h5-line-height );
}

h6,
.h6 {
  margin-bottom: 12px;
  padding-top: 8px;
  font-size: var( --h6-font-size );
  line-height: var( --h6-line-height );
}
