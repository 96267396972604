/* -----------------------------------------------------------------------------
 *
 * List: General
 *
 * ----------------------------------------------------------------------------- */

ul,
ol {
  margin-top: 0;
  margin-bottom: 20px;
  padding-left: 32px;
}

li {
  margin-bottom: 8px;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-top: 8px;
  margin-bottom: 0;
}
