/* -----------------------------------------------------------------------------
 *
 * Utilities: Grid
 *
 * ----------------------------------------------------------------------------- */

@define-mixin grid-column-reset {
  width: 100%;
}

@define-mixin mobile-container {
  margin: 0 auto;
  padding-right: var( --grid-gutter );
  padding-left: var( --grid-gutter );

  @media (--medium) {
    max-width: var( --grid-width-default );
    padding-right: 0;
    padding-left: 0;
  }
}
