/* -----------------------------------------------------------------------------
 *
 * Layout: Global Content Container
 *
 * ----------------------------------------------------------------------------- */

.l-container {
  @mixin clearfix;

  width: 100%;
  max-width: var( --grid-width );
  margin-right: auto;
  margin-left: auto;
  padding-right: var( --grid-gutter );
  padding-left: var( --grid-gutter );
}
