/* -----------------------------------------------------------------------------
 *
 * Regions: Header
 *
 * ----------------------------------------------------------------------------- */


/* -----------------------------------------------------------------------------
 * Site Header
 * ----------------------------------------------------------------------------- */

.site-header {
  position: relative;
  z-index: 999;
  background-color: var( --color-torch-red );
  position: fixed;
  width: 100%;

  & > .l-container {
    max-width: none;
    padding-top: 4px;
    padding-bottom: 2px;

    @media (--large) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: var( --grid-width );
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.site-header.navigation-open {
  @media (--large-max) {
    &::before {
      content: '';
      position: fixed;
      z-index: 8000;
      top: 42px;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      transition: opacity 225ms cubic-bezier( 0.4, 0, 0.2, 1 ) 0ms;
      opacity: 0.7;
      background-color: var( --color-midnight-express );
    }
  }
}

.site-header.navigation-open.sticky-nav {
  @media (--large-max) {
    &::before {
      top: 88px;
    }
  }
}

/* -----------------------------------------------------------------------------
 * Site Header Primary
 * ----------------------------------------------------------------------------- */

.site-header-primary {
  display: flex;
  align-items: center;
  width: calc(50% + 12px);
  justify-content: space-between;

  @media (--large) {
    width: unset;
  }
}

/* -----------------------------------------------------------------------------
 * Navigation Toggle Wrap
 * ----------------------------------------------------------------------------- */

.navigation-toggle-wrap {
  @media (--large) {
    flex: auto;
  }
}

/* -----------------------------------------------------------------------------
 * Navigation Toggle
 * ----------------------------------------------------------------------------- */

.navigation-toggle {
  padding: 6px 0;
  border: transparent;
  background-color: transparent;
  cursor: pointer;

  @media (--large) {
    display: none;
  }
}

.navigation-toggle .navigation-toggle-icon,
.navigation-toggle .navigation-toggle-icon::before,
.navigation-toggle .navigation-toggle-icon::after {
  content: '';
  display: block;
  position: absolute;
  width: 24px;
  height: 2px;
  margin-right: auto;
  margin-left: auto;
  transition: all 300ms ease-out;
  background-color: var( --color-white );
}

.navigation-toggle .navigation-toggle-icon {
  position: relative;
  margin-top: 12px;
  margin-bottom: 12px;

  &::before {
    top: -8px;
  }

  &::after {
    bottom: -8px;
  }
}

.navigation-toggle:hover .navigation-toggle-icon,
.navigation-toggle:focus .navigation-toggle-icon,
.navigation-toggle:hover .navigation-toggle-icon::before,
.navigation-toggle:focus .navigation-toggle-icon::before,
.navigation-toggle:hover .navigation-toggle-icon::after,
.navigation-toggle:focus .navigation-toggle-icon::after {
  background-color: var( --color-white );
}

/* -----------------------------------------------------------------------------
 * Navigation Toggle - Open Navigation
 * ----------------------------------------------------------------------------- */

.navigation-open.navigation-toggle .navigation-toggle-icon {
  background-color: transparent;

  &::before,
  &::after {
    top: 0;
  }

  &::before {
    transform: rotate( 45deg );
  }

  &::after {
    transform: rotate( -45deg );
  }
}

/* -----------------------------------------------------------------------------
 * Site Branding
 * ----------------------------------------------------------------------------- */

.site-branding {
  @media (--large) {
    flex: auto;
  }
}

.site-brand {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  text-decoration: none;

  @media (--large) {
    width: 36px;
    height: 36px;
  }
}

.site-title {
  margin: 0;
  padding: 0;
  line-height: 1;
}
