:root {
  // --font-primary is defined in _app.js, --font-family-primary is used by Bricks
  --font-family-primary: var(--font-primary);

  // Mobile header fonts
  --display-sm-font-size-mobile: rem(32px);
  --display-sm-line-height-mobile: lh(32px, 36px);

  --heading-3xl-font-size-mobile: rem(32px);
  --heading-3xl-line-height-mobile: lh(32px, 36px);

  --heading-2xl-font-size-mobile: rem(30px);
  --heading-2xl-line-height-mobile: lh(30px, 34px);

  --heading-xl-font-size-mobile: rem(24px);
  --heading-xl-line-height-mobile: lh(24px, 28px);

  --heading-lg-font-size-mobile: rem(20px);
  --heading-lg-line-height-mobile: lh(20px, 24px);

  --heading-md-font-size-mobile: rem(18px);
  --heading-md-line-height-mobile: lh(18px, 22px);

  --heading-sm-font-size-mobile: rem(16px);
  --heading-sm-line-height-mobile: lh(16px, 20px);
  // End Mobile header fonts

  // Desktop header fonts
  --display-sm-font-size-desktop: rem(50px);
  --display-sm-line-height-desktop: lh(50px, 50px);

  --heading-3xl-font-size-desktop: rem(64px);
  --heading-3xl-line-height-desktop: lh(64px, 68px);

  --heading-2xl-font-size-desktop: rem(56px);
  --heading-2xl-line-height-desktop: lh(56px, 60px);

  --heading-xl-font-size-desktop: rem(42px);
  --heading-xl-line-height-desktop: lh(42px, 46px);

  --heading-lg-font-size-desktop: rem(30px);
  --heading-lg-line-height-desktop: lh(30px, 34px);

  --heading-md-font-size-desktop: rem(24px);
  --heading-md-line-height-desktop: lh(24px, 28px);

  --heading-sm-font-size-desktop: rem(18px);
  --heading-sm-line-height-desktop: lh(18px, 22px);
  // End Desktop header fonts

  /**
    START BRICKS TYPOGRAPHY
    We break out the header font sizes and line heights above because
    while 90% of the time we want the responsive nature setup below
    and plugged into breaks, there are one-off cases where we don't
    and we can use the above mobile vs desktop variables

    See more in the README.md
  **/
    // Below variables exist in Bricks
  // but we dont current use in our Frontier Core Styles
  // --display-lg-font-size
  // --display-lg-line-height
  // --display-lg-letter-spacing
  // --display-lg-font-weight

  // --display-md-font-size
  // --display-md-line-height
  // --display-md-letter-spacing
  // --display-md-font-weight

  --display-sm-font-size: var(--display-sm-font-size-mobile);
  --display-sm-line-height: var(--display-sm-line-height-mobile);
  --display-sm-letter-spacing: 0%;
  --display-sm-font-weight: 400;
  --display-sm-font-family: var(--font-bandwidth);

  --heading-3xl-font-size: var(--heading-3xl-font-size-mobile);
  --heading-3xl-line-height: var(--heading-3xl-line-height-mobile);
  --heading-3xl-letter-spacing: 0%;
  --heading-3xl-font-weight: 400;
  --heading-3xl-font-family: var(--font-bandwidth);

  --heading-2xl-font-size: var(--heading-2xl-font-size-mobile);
  --heading-2xl-line-height: var(--heading-2xl-line-height-mobile);
  --heading-2xl-letter-spacing: 0%;
  --heading-2xl-font-weight: 700;

  --heading-xl-font-size: var(--heading-xl-font-size-mobile);
  --heading-xl-line-height: var(--heading-xl-line-height-mobile);
  --heading-xl-letter-spacing: 0%;
  --heading-xl-font-weight: 700;

  --heading-lg-font-size: var(--heading-lg-font-size-mobile);
  --heading-lg-line-height: var(--heading-lg-line-height-mobile);
  --heading-lg-letter-spacing: 0%;
  --heading-lg-font-weight: 700;

  --heading-md-font-size: var(--heading-md-font-size-mobile);
  --heading-md-line-height: var(--heading-md-line-height-mobile);
  --heading-md-letter-spacing: 0%;
  --heading-md-font-weight: 700;

  --heading-sm-font-size: var(--heading-sm-font-size-mobile);
  --heading-sm-line-height: var(--heading-sm-line-height-mobile);
  --heading-sm-letter-spacing: 0%;
  --heading-sm-font-weight: 700;

  // Our headings are responsive based on screensize
  @media (--medium) {
      --display-sm-font-size: var(--display-sm-font-size-desktop);
      --display-sm-line-height: var(--display-sm-line-height-desktop);
      --display-sm-letter-spacing: 0%;
      --display-sm-font-weight: 400;

      --heading-3xl-font-size: var(--heading-3xl-font-size-desktop);
      --heading-3xl-line-height: var(--heading-3xl-line-height-desktop);
      --heading-3xl-letter-spacing: 0%;
      --heading-3xl-font-weight: 400;

      --heading-2xl-font-size: var(--heading-2xl-font-size-desktop);
      --heading-2xl-line-height: var(--heading-2xl-line-height-desktop);
      --heading-2xl-letter-spacing: 0%;
      --heading-2xl-font-weight: 700;

      --heading-xl-font-size: var(--heading-xl-font-size-desktop);
      --heading-xl-line-height: var(--heading-xl-line-height-desktop);
      --heading-xl-letter-spacing: 0%;
      --heading-xl-font-weight: 700;

      --heading-lg-font-size: var(--heading-lg-font-size-desktop);
      --heading-lg-line-height: var(--heading-lg-line-height-desktop);
      --heading-lg-letter-spacing: 0%;
      --heading-lg-font-weight: 700;

      --heading-md-font-size: var(--heading-md-font-size-desktop);
      --heading-md-line-height: var(--heading-md-line-height-desktop);
      --heading-md-letter-spacing: 0%;
      --heading-md-font-weight: 700;

      --heading-sm-font-size: var(--heading-sm-font-size-desktop);
      --heading-sm-line-height: var(--heading-sm-line-height-desktop);
      --heading-sm-letter-spacing: 0%;
      --heading-sm-font-weight: 700;
  }
  --subhead-lg-font-size: rem(20px);
  --subhead-lg-line-height: lh(20px, 24px);
  --subhead-lg-letter-spacing: 0%;
  --subhead-lg-font-weight: 400;

  --subhead-md-font-size: rem(16px);
  --subhead-md-line-height: lh(16px, 20px);
  --subhead-md-letter-spacing: 0%;
  --subhead-md-font-weight: 400;

  --subhead-sm-font-size: rem(12px);
  --subhead-sm-line-height: lh(12px, 16px);
  --subhead-sm-letter-spacing: 0%;
  --subhead-sm-font-weight: 400;

  --body-lg-font-size: rem(20px);
  --body-lg-line-height: lh(20px, 28px);
  --body-lg-letter-spacing: 0%;
  --body-lg-font-weight: 400;

  --body-md-font-size: rem(16px);
  --body-md-line-height: lh(16px, 20px);
  --body-md-letter-spacing: 0%;
  --body-md-font-weight: 400;

  --body-sm-font-size: rem(14px);
  --body-sm-line-height: lh(14px, 18px);
  --body-sm-letter-spacing: 0%;
  --body-sm-font-weight: 400;

    // Below variables exist in Bricks
  // but we dont current use in our Frontier Core Styles
  // --quote-lg-font-size: rem(
  // --quote-lg-line-height: ln(
  // --quote-lg-font-weight

  // --quote-md-font-size: rem(
  // --quote-md-line-height: ln(
  // --quote-md-font-weight

  // --quote-sm-font-size: rem(
  // --quote-sm-line-height: ln(
  // --quote-sm-font-weight

  --caption-lg-font-size: rem(12px);
  --caption-lg-line-height: lh(12px, 16px);
  --caption-lg-letter-spacing: 0%;
  --caption-lg-font-weight: 400;

  --caption-md-font-size: rem(10px);
  --caption-md-line-height: lh(10px, 14px);
  --caption-md-letter-spacing: 0%;
  --caption-md-font-weight: 400;

  --caption-sm-font-size: rem(8px);
  --caption-sm-line-height: lh(8px, 12px);
  --caption-sm-letter-spacing: 0%;
  --caption-sm-font-weight: 400;
}