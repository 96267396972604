/* -----------------------------------------------------------------------------
 *
 * Grid: Columns
 *
 * ----------------------------------------------------------------------------- */

.g-col {
  position: relative;
  width: 100%;
  min-width: 0;
}

/* -----------------------------------------------------------------------------
 * Columns: Modifier Vertical center content | NOTE: This is a safari fix too.
 * ----------------------------------------------------------------------------- */

.g-col--vertical-center {
  display: flex;
  align-items: center;
}

/* -----------------------------------------------------------------------------
 * Columns: Modifier Horizontally center content
 * ----------------------------------------------------------------------------- */

.g-col--horizontal-center {
  display: flex;
  justify-content: center;
}
