/*********
Figma Exported Variables, formatted by Bricks
*********/

:root {
  /*********
   colors
*********/

  /* color/neutral */
  --black: #212121;
  --neutral-100: #f5f5f5;
  --neutral-200: #eee;
  --neutral-300: #d4d4d4;
  --neutral-500: #9e9e9e;
  --neutral-600: #757575;
  --neutral-700: #616161;
  --neutral-800: #424242;
  --neutral-900: #313131;
  --transparent: #fff0;
  --white: #fff;

  /* color/primary */
  --primary-250: #ffbfcd;
  --primary-50: #fff2f5;
  --primary-500: #ff809b;
  --primary-900: #ff1a4b;
  --primary-base: #ff0037;

  /* color/secondary */
  --secondary-250: #c4c5c9;
  --secondary-50: #f3f4f4;
  --secondary-500: #898c93;
  --secondary-900: #2d3548;
  --secondary-base: #141928;

  /* color/support */
  --primary-error: #ed0037;
  --primary-info: #0043ce;
  --primary-success: #13892e;
  --primary-warning: #f1c21b;
  --secondary-error: #f8ced0;
  --secondary-info: #cdf;
  --secondary-success: #d4f7df;
  --secondary-warning: #fdf6dd;
  --tertiary-error: #550c10;
  --tertiary-info: #013;
  --tertiary-success: #082b13;
  --tertiary-warning: #3e3204;

  /* color/tertiary */
  --tertiary-200: #eafffd;
  --tertiary-500: #cbfffa;
  --tertiary-900: #a1fff6;
  --tertiary-base: #96fff5;

  /*********
   numbers
*********/

  /* size */
  --icon-size-sm: var( --size-lg );
  --icon-size-md: var( --size-xl );
  --icon-size-lg: var( --size-2xl );
  --icon-size-xl: var( --size-4xl );
  --size-xxs: 0.125rem;
  --size-xs: 0.25rem;
  --size-sm: 0.5rem;
  --size-md: 0.75rem;
  --size-lg: 1rem;
  --size-xl: 1.5rem;
  --size-2xl: 2rem;
  --size-3xl: 3rem;
  --size-4xl: 4rem;
  --size-5xl: 8rem;
  --viewports-base-1: 20rem;
  --viewports-base-2: 23.4375rem;
  --viewports-sm-1: 23.5rem;
  --viewports-sm-2: 47.9375rem;
  --viewports-md-1: 48rem;
  --viewports-md-2: 76.4375rem;
  --viewports-xl-1: 76.5rem;
  --viewports-xl-2: 90rem;


  /*********
   colors
*********/

  /* disabled */
  --action-disabled-bg: var( --neutral-200 );
  --action-disabled-border: var( --neutral-500 );
  --action-disabled-label: var( --neutral-500 );

  /* focus */
  --action-focus: #1f46b9;
  --action-focus-inset-bg: #fcfcfc03;
  --action-focus-light: var( --white );

  /* layer-action */
  --action-layer-border: var( --primary-base );

  /* focus */
  --action-layer-hover-bg: var( --secondary-50 );

  /* link-button */
  --action-link: var( --secondary-base );
  --action-link-active: var( --primary-base );
  --action-link-focus: var( --secondary-base );
  --action-link-hover: var( --primary-base );
  --action-link-hover-active-bg: var( --transparent );
  --action-link-light: var( --white );
  --action-link-light-active: var( --primary-base );
  --action-link-light-focus: var( --white );
  --action-link-light-hover: var( --primary-base );
  --action-link-light-hover-active-bg: var( --transparent );

  /* plain-button */
  --action-button-plain-hover-active-bg: var( --transparent );
  --action-button-plain-label: var( --secondary-base );
  --action-button-plain-label-active: var( --primary-base );
  --action-button-plain-label-focus: var( --secondary-base );
  --action-button-plain-label-hover: var( --primary-base );
  --action-button-plain-label-light: var( --white );
  --action-button-plain-label-light-active: var( --primary-base );
  --action-button-plain-label-light-focus: var( --white );
  --action-button-plain-label-light-hover: var( --primary-base );
  --action-button-plain-light-hover-active-bg: var( --transparent );

  /* primary-button */
  --action-button-primary-active: var( --secondary-base );
  --action-button-primary-default: var( --primary-base );
  --action-button-primary-focus: var( --primary-base );
  --action-button-primary-hover: var( --secondary-base );
  --action-button-primary-inverse: var( --white );
  --action-button-primary-light-active: var( --white );
  --action-button-primary-light-default: var( --primary-base );
  --action-button-primary-light-focus: var( --primary-base );
  --action-button-primary-light-hover: var( --white );
  --action-button-primary-light-inverse: var( --white );

  /* secondary-button */
  --action-button-secondary-active: var( --primary-base );
  --action-button-secondary-default: var( --secondary-base );
  --action-button-secondary-focus: var( --secondary-base );
  --action-button-secondary-hover: var( --primary-base );
  --action-button-secondary-inverse: var( --white );
  --action-button-secondary-light-active: var( --primary-base );
  --action-button-secondary-light-default: var( --white );
  --action-button-secondary-light-focus: var( --white );
  --action-button-secondary-light-hover: var( --primary-base );
  --action-button-secondary-light-inverse: var( --secondary-base );

  /* surface/neutral */
  --surface-neutral-black: var( --black );
  --surface-neutral-dark: var( --neutral-900 );
  --surface-neutral-light: var( --neutral-100 );
  --surface-neutral-mid: var( --neutral-500 );
  --surface-neutral-white: var( --white );

  /* surface/primary */
  --surface-primary-dark: var( --primary-base );
  --surface-primary-dim: var( --primary-900 );
  --surface-primary-light: var( --primary-50 );
  --surface-primary-mid: var( --primary-250 );

  /* surface/secondary */
  --surface-secondary-dark: var( --secondary-base );
  --surface-secondary-dim: var( --secondary-900 );
  --surface-secondary-light: var( --secondary-50 );
  --surface-secondary-mid: var( --secondary-500 );

  /* surface/tertiary */
  --surface-tertiary-dark: var( --tertiary-base );
  --surface-tertiary-dim: var( --tertiary-900 );
  --surface-tertiary-light: var( --tertiary-200 );
  --surface-tertiary-mid: var( --tertiary-500 );

  /* tertiary-button */
  --action-button-tertiary-active: var( --secondary-base );
  --action-button-tertiary-default: var( --tertiary-base );
  --action-button-tertiary-focus: var( --tertiary-base );
  --action-button-tertiary-hover: var( --secondary-base );
  --action-button-tertiary-inverse: var( --secondary-base );
  --action-button-tertiary-light-active: var( --white );
  --action-button-tertiary-light-default: var( --tertiary-base );
  --action-button-tertiary-light-focus: var( --tertiary-base );
  --action-button-tertiary-light-hover: var( --white );
  --action-button-tertiary-light-inverse: var( --secondary-base );

  /* text/neutral */
  --text-neutral-dark: var( --neutral-900 );
  --text-neutral-inverse-dark: var( --neutral-100 );
  --text-neutral-inverse-light: var( --white );
  --text-neutral-light: var( --neutral-700 );
  --text-neutral-mid: var( --neutral-800 );

  /* text/primary */
  --text-primary-dark: var( --primary-base );
  --text-primary-inverse-dark: var( --primary-250 );
  --text-primary-inverse-light: var( --primary-50 );
  --text-primary-light: var( --primary-900 );

  /* text/secondary */
  --text-secondary-dark: var( --secondary-base );
  --text-secondary-inverse-dark: var( --secondary-250 );
  --text-secondary-inverse-light: var( --secondary-50 );
  --text-secondary-light: var( --secondary-900 );

  /* text/tertiary */
  --text-tertiary-dark: var( --tertiary-base );
  --text-tertiary-inverse-dark: var( --tertiary-500 );
  --text-tertiary-inverse-light: var( --tertiary-200 );
  --text-tertiary-light: var( --tertiary-900 );

  /*********
   numbers
*********/

  /* padding */
  --padding-xxs: var( --size-xxs );
  --padding-xs: var( --size-xs );
  --padding-sm: var( --size-sm );
  --padding-md: var( --size-md );
  --padding-lg: var( --size-lg );
  --padding-xl: var( --size-xl );
  --padding-2xl: var( --size-2xl );
  --padding-3xl: var( --size-3xl );
  --padding-4xl: var( --size-4xl );


  /* horizontal padding */
  --horizontal-padding: var(--padding-lg);

  @media ( --medium ) {
    --horizontal-padding: var(--padding-2xl);
  }

  @media ( --hd ) {
    --horizontal-padding: var(--padding-4xl);
  }

  /* radius */
  --radius-xxs: var( --size-xxs );
  --radius-xs: var( --size-xs );
  --radius-sm: var( --size-sm );
  --radius-md: var( --size-md );
  --radius-lg: var( --size-lg );
  --radius-xl: var( --size-xl );
  --radius-2xl: var( --size-2xl );
  --radius-3xl: var( --size-3xl );
  --radius-4xl: var( --size-4xl );

  /* spacing */
  --spacing-xxs: var( --size-xxs );
  --spacing-xs: var( --size-xs );
  --spacing-sm: var( --size-sm );
  --spacing-md: var( --size-md );
  --spacing-lg: var( --size-lg );
  --spacing-xl: var( --size-xl );
  --spacing-2xl: var( --size-2xl );
  --spacing-3xl: var( --size-3xl );
  --spacing-4xl: var( --size-4xl );
  --spacing-5xl: var( --size-5xl );
}
