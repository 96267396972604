/* -----------------------------------------------------------------------------
 *
 * Regions: Footer
 *
 * ----------------------------------------------------------------------------- */

.site-footer {
  padding: 60px 0;

  @media (--large) {
    padding: 76px 0 88px;
  }
}

/* -----------------------------------------------------------------------------
 * Site Footer Primary
 * ----------------------------------------------------------------------------- */

.site-footer-primary {
  @media (--large) {
    width: 100%;
    max-width: var( --grid-width );
    margin-right: auto;
    margin-left: auto;
    padding-right: var( --grid-gutter );
    padding-left: var( --grid-gutter );
  }
}

.footer-menu-primary .footer-menu-links {
  @mixin mobile-container;
  margin-top: 0;
  display: flex;
  flex-direction: column;

  @media (--large) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  & *::before,
  & *::after {
    @media (--large) {
      content: none;
    }
  }
}

.footer-menu-primary .link-title {
  color: var( --color-midnight-express );
  font-size: rem( 18px );
  line-height: lh( 18px, 22px );
  font-weight: 700;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var( --color-ghost-gray );

  @media (--large) {
    border-bottom: none;
  }

  &:hover {
    text-decoration: none;
  }
}

.footer-menu-primary li {
  font-size: rem( 14px );
  font-weight: 500;
  line-height: lh( 14px, 14px );
}

.footer-menu-primary .menu > li > a {
  display: block;
  padding: 10px 0;
  font-size: rem( 16px );
  font-weight: normal;
  line-height: lh( 16px, 20px );
  pointer-events: auto;

  &:hover,
  &:focus {
  }
}

/* -----------------------------------------------------------------------------
 * Site Footer Secondary
 * ----------------------------------------------------------------------------- */

.site-footer-secondary {
  @mixin mobile-container;
}

.site-footer-secondary .social {
  margin-top: 20px;
  margin-bottom: 0;

  @media (--hd) {
    margin-top: 0;
    float: right;
  }
}

/* -----------------------------------------------------------------------------
 * Footer Menu - Secondary
 * ----------------------------------------------------------------------------- */

.footer-menu-secondary {
  @media (--hd) {
    float: left;
  }
}

.footer-menu-secondary li {
  display: inline-block;
  width: 50%;
  font-size: rem( 12px );

  @media (--medium) {
    width: auto;
    margin-right: 25px;
  }
}

.footer-menu-secondary li > a {
  display: block;
  padding: 10px 10px 10px 0;
  font-weight: 500;
}

/* -----------------------------------------------------------------------------
 * Site Copyright
 * ----------------------------------------------------------------------------- */

.site-copyright {
  color: var( --color-midnight-express );
}

/* Cookie consent button style overrides */
li#ot-sdk-btn.ot-sdk-show-settings {
  color: var( --color-midnight-express );
  border: none;
  background: none;
  font-weight: 500;
  font-size: rem( 12px );
  padding: 10px 10px 10px 0;
  width: auto;

  &:hover {
    color: var( --color-midnight-express );
    text-decoration: underline;
    background: none;
  }
}
