/* -----------------------------------------------------------------------------
 *
 * Components: Tooltip
 *
 * ----------------------------------------------------------------------------- */

.tippy-touch {
  cursor: pointer !important;
}

[ data-tippy-root ] .tippy-content {
  padding: 12px 16px;
  border-radius: 4px;
  text-align: left;

  p:last-of-type {
    margin-bottom: 0;
  }
}

.tooltip-btn {
  padding: 0;
  border: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
}

.tooltip-icon {
  cursor: pointer;
}

.tooltip-content--legal {
  max-width: 200px;
  margin-bottom: 0;
  font-family: var( --font-primary );
  font-size: rem( 14px );
  line-height: lh( 14px, 18px );
}

/* -----------------------------------------------------------------------------
* Light Theme
* ----------------------------------------------------------------------------- */

[ data-tippy-root ] .tippy-box[ data-theme='light' ] {
  background-color: var( --color-white );
  box-shadow: 0 0 10px color-mod( var( --color-black ) a( 10% ) );
  color: var( --color-midnight-express );
}

[ data-tippy-root ] .tippy-box[ data-theme='light' ] .tippy-arrow {
  color: var( --color-white );
}

/* -----------------------------------------------------------------------------
* Dark Theme
* ----------------------------------------------------------------------------- */

[ data-tippy-root ] .tippy-box[ data-theme='dark' ] {
  background-color: var( --color-midnight-express );
  box-shadow: 0 0 10px color-mod( var( --color-black ) a( 10% ) );
  color: var( --color-white );
}

[ data-tippy-root ] .tippy-box[ data-theme='dark' ] .tippy-arrow {
  color: var( --color-midnight-express );
}
