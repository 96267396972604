/* -----------------------------------------------------------------------------
 *
 * Components: Dynamic Button
 *
 * ----------------------------------------------------------------------------- */

.component__btn {
  @media (--medium) {
    width: auto;
  }
}
