:root {
	--icon-chevron-left: '\eaec';
	--icon-chevron-down: '\eaed';
	--icon-chevron-up: '\eaee';
	--icon-chevron-right: '\eaeb';
	--icon-person-circle: '\e93a';
	--icon-padlock-circle: '\e93b';
	--icon-play-circle: '\e93c';
	--icon-refresh-circle: '\e93d';
	--icon-star-circle: '\e93f';
	--icon-thumbs-up-circle: '\e940';
	--icon-bolt-circle: '\e944';
	--icon-check-short: '\e945';
	--icon-location-marker-alt: '\e939';
	--icon-checkmark1: '\ea10';
	--icon-quotes-left: '\e977';
	--icon-quotes-right: '\e978';
	--icon-minus2: '\ea0b';
	--icon-arrow-left2: '\ea40';
	--icon-arrow-down2: '\ea3e';
	--icon-arrow-up2: '\ea3a';
	--icon-arrow-right2: '\ea3c';
	--icon-arrow-up-down: '\e955';
	--icon-play: '\ea15';
	--icon-close-bold: '\ea0f';
	--icon-instagram: '\ea92';
	--icon-autopay: '\e959';
	--icon-router: '\e958';
	--icon-global: '\e957';
	--icon-chevron-left1: '\eaf0';
	--icon-chevron-down1: '\eaf1';
	--icon-chevron-up1: '\eaf2';
	--icon-chevron-right1: '\eaef';
	--icon-caret-left: '\f0d9';
	--icon-caret-down: '\f0d7';
	--icon-caret-up: '\f0d8';
	--icon-caret-right: '\f0da';
	--icon-play3: '\e923';
	--icon-tv-circle: '\e941';
	--icon-wifi-circle: '\e93e';
	--icon-checkmark-small: '\e938';
	--icon-checkmark2: '\e951';
	--icon-arrow-up-down2: '\e956';
	--icon-location-marker: '\e934';
	--icon-location-marker-2: '\e91f';
	--icon-shield-check: '\e92d';
	--icon-tech-support: '\e953';
	--icon-gift-card: '\e952';
	--icon-price-tag-v2: '\e950';
	--icon-info-outline: '\e94e';
	--icon-price-lock: '\e94c';
	--icon-wrench: '\e94b';
	--icon-monitor: '\e948';
	--icon-padlock-unlocked: '\e949';
	--icon-modern-game-controller: '\e947';
	--icon-cables: '\e920';
	--icon-global-wifi: '\e921';
	--icon-speed-gauge: '\e924';
	--icon-whole-home-wifi: '\e925';
	--icon-wifi-v3: '\e946';
	--icon-cloud-upload-red: '\e91e';
	--icon-upload-download-cloud: '\e94d';
	--icon-user: '\e90a';
	--icon-users: '\e91b';
	--icon-user-v2: '\e91c';
	--icon-invoice: '\e94f';
	--icon-money-sign: '\e926';
	--icon-price-tag: '\e92a';
	--icon-stopwatch: '\e942';
	--icon-clock: '\e927';
	--icon-circled-check-mark: '\e91d';
	--icon-star-v3: '\e928';
	--icon-calendar: '\e929';
	--icon-phone-outline: '\e943';
	--icon-magnifying-glass: '\e935';
	--icon-shopping-cart: '\e936';
	--icon-cart: '\e901';
	--icon-youtube: '\e90b';
	--icon-play1: '\e916';
	--icon-play2: '\e932';
	--icon-heart: '\e933';
	--icon-star: '\e92b';
	--icon-shield: '\e918';
	--icon-youtube-v2: '\e954';
	--icon-wifi: '\e92c';
	--icon-close: '\e90d';
	--icon-minus: '\e902';
	--icon-plus: '\e90c';
	--icon-book: '\e900';
	--icon-exclamation: '\e903';
	--icon-facebook: '\e904';
	--icon-info: '\e905';
	--icon-linkedin: '\e906';
	--icon-phone: '\e907';
	--icon-search: '\e908';
	--icon-twitter: '\e909';
	--icon-ban: '\e90e';
	--icon-caution: '\e90f';
	--icon-game-controller: '\e910';
	--icon-gem: '\e911';
	--icon-home: '\e912';
	--icon-key: '\e913';
	--icon-lock: '\e915';
	--icon-repeat: '\e917';
	--icon-thumbs-up: '\e919';
	--icon-tv: '\e91a';
	--icon-laptop: '\e914';
	--icon-credit-card: '\e92e';
	--icon-stacked-hamburglar: '\e94a';
	--icon-laptop-v2: '\e922';
	--icon-life-ring: '\e92f';
	--icon-locked: '\e930';
	--icon-money: '\e931';
	--icon-up-trend: '\e937';
	--icon-twitter-x: '\e95a';
}
