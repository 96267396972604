/* -----------------------------------------------------------------------------
 *
 * Components: General
 *
 * ----------------------------------------------------------------------------- */

.component {
  padding: 72px 0;

  @media (--medium) {
    padding: 100px 0;
  }
}

/* -----------------------------------------------------------------------------
 * Container
 * ----------------------------------------------------------------------------- */

.component > .l-container {
  @mixin mobile-container;
}

/* -----------------------------------------------------------------------------
 * Header
 * ----------------------------------------------------------------------------- */

.component__header {
  color: var( --color-midnight-express );
  font-family: var( --font-primary );
}

/* -----------------------------------------------------------------------------
 * Pretitle
 * ----------------------------------------------------------------------------- */

.component__pretitle {
  color: var( --color-midnight-express );
  font-size: rem( 14px );
  font-weight: 500;
  line-height: lh( 14px, 14px );
  text-transform: uppercase;
}

/* -----------------------------------------------------------------------------
 * Title
 * ----------------------------------------------------------------------------- */

.component__title {
  font-size: rem( 32px );
  font-weight: 500;
  line-height: lh( 32px, 38px );

  @media (--medium) {
    font-size: rem( 44px );
    line-height: lh( 44px, 46px );
  }

  @media (--large) {
    font-size: rem( 48px );
    line-height: lh( 48px, 58px );
  }
}

/* -----------------------------------------------------------------------------
 * Subtitle
 * ----------------------------------------------------------------------------- */

.component__subtitle {
  font-size: rem( 16px );
  line-height: lh( 16px, 22px );

  @media (--medium) {
    font-size: rem( 20px );
    line-height: lh( 20px, 28px );
  }
}

/* -----------------------------------------------------------------------------
 * Buttons
 * ----------------------------------------------------------------------------- */

.component .btn {
  text-transform: uppercase;
}
