/* -----------------------------------------------------------------------------
 *
 * Components: Buttons
 *
 * ----------------------------------------------------------------------------- */

[ role='button' ] {
  cursor: pointer;
}

.btn {
  display: inline-block;
  width: 100%;
  height: auto;
  padding: 14px 24px;
  border: 1px solid var( --color-primary );
  border-radius: 25px;
  background-color: var( --color-primary );
  box-shadow: none;
  color: var( --color-white );
  font-size: rem( 18px );
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
  line-height: lh( 18px, 22px );
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: normal;
  cursor: pointer;

  @media (--large) {
    width: auto;
  }

  &:hover:enabled,
  &:focus:enabled {
    transition: all 0.3s ease;
    background-color: var( --color-white );
    color: var( --color-primary );
    text-decoration: none;
  }

  &[ disabled ],
  &.disabled {
    opacity: 0.5;
    color: var( --color-white );
    cursor: not-allowed;
  }
}

.btn + .btn {
  margin-top: 16px;

  @media (--hd) {
    margin-top: 0;
    margin-left: 12px;
  }
}

a.btn[ href^='tel' ] {
  color: var( --color-white );
}

a.btn[ href^='tel' ]:hover {
  color: var( --color-midnight-express );
  text-decoration: underline;
}

a.btn > span.icon {
  pointer-events: none;
}

/* -----------------------------------------------------------------------------
 * Button Sizes
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 * Button Large
 * ----------------------------------------------------------------------------- */

.btn-large {
  padding-top: 13px;
  padding-bottom: 13px;
}

/* -----------------------------------------------------------------------------
 * Button Medium
 * ----------------------------------------------------------------------------- */

.btn-medium {
  padding: 15px 30px;
}

/* -----------------------------------------------------------------------------
 * Button Small
 * ----------------------------------------------------------------------------- */

.btn-small {
  padding-top: 5px;
  padding-bottom: 5px;
}

/* -----------------------------------------------------------------------------
 * Contextual Variations
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 * Button Primary
 * ----------------------------------------------------------------------------- */

.btn--blue {
  border-color: var( --color-tertiary );
  background-color: var( --color-tertiary );
  color: var( --color-secondary );

  &:hover,
  &:focus,
  &:hover:enabled,
  &:focus:enabled {
    border-color: var( --color-secondary );
    background-color: var( --color-white );
    color: var( --color-secondary );
  }
}

/* -----------------------------------------------------------------------------
 * Button Secondary
 * ----------------------------------------------------------------------------- */

.btn--white {
  border-color: var( --color-secondary );
  background-color: var( --color-white );
  color: var( --color-secondary );

  &:hover,
  &:focus,
  &:hover:enabled,
  &:focus:enabled {
    background-color: var( --color-secondary );
    color: var( --color-white );
  }
}

/* -----------------------------------------------------------------------------
 * Button Tertiary
 * ----------------------------------------------------------------------------- */

.btn--dark {
  border-color: var( --color-secondary );
  background-color: var( --color-secondary );
  color: var( --color-white );

  &:hover,
  &:focus,
  &:hover:enabled,
  &:focus:enabled {
    background-color: var( --color-white );
    color: var( --color-secondary );
  }
}

/* -----------------------------------------------------------------------------
 * Button Outline Primary
 * ----------------------------------------------------------------------------- */

.btn--blackOutline {
  border-width: 3px;
  border-color: var( --color-black );
  background-color: transparent;
  color: var( --color-black );

  &:hover,
  &:focus {
    background-color: var( --color-black );
    color: var( --color-white );
  }
}

/* -----------------------------------------------------------------------------
 * Button Outline Secondary
 * ----------------------------------------------------------------------------- */

 .btn--whiteOutline {
  border-width: 3px;
  border-color: var( --color-white );
  background-color: transparent;
  color: var( --color-white );

  &:hover,
  &:focus {
    background-color: var( --color-white );
    color: var( --color-secondary );
  }
}