@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?8a2u1t');
  src:  url('fonts/icomoon.eot?8a2u1t#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?8a2u1t') format('truetype'),
    url('fonts/icomoon.woff?8a2u1t') format('woff'),
    url('fonts/icomoon.svg?8a2u1t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron-left:before {
  content: "\eaec";
}
.icon-chevron-down:before {
  content: "\eaed";
}
.icon-chevron-up:before {
  content: "\eaee";
}
.icon-chevron-right:before {
  content: "\eaeb";
}
.icon-person-circle:before {
  content: "\e93a";
}
.icon-padlock-circle:before {
  content: "\e93b";
}
.icon-play-circle:before {
  content: "\e93c";
}
.icon-refresh-circle:before {
  content: "\e93d";
}
.icon-star-circle:before {
  content: "\e93f";
}
.icon-thumbs-up-circle:before {
  content: "\e940";
}
.icon-bolt-circle:before {
  content: "\e944";
}
.icon-check-short:before {
  content: "\e945";
}
.icon-location-marker-alt:before {
  content: "\e939";
}
.icon-checkmark1:before {
  content: "\ea10";
}
.icon-quotes-left:before {
  content: "\e977";
}
.icon-quotes-right:before {
  content: "\e978";
}
.icon-minus2:before {
  content: "\ea0b";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-arrow-down2:before {
  content: "\ea3e";
}
.icon-arrow-up2:before {
  content: "\ea3a";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
.icon-arrow-up-down:before {
  content: "\e955";
}
.icon-play:before {
  content: "\ea15";
}
.icon-close-bold:before {
  content: "\ea0f";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-autopay:before {
  content: "\e959";
}
.icon-router:before {
  content: "\e958";
}
.icon-global:before {
  content: "\e957";
}
.icon-chevron-left1:before {
  content: "\eaf0";
}
.icon-chevron-down1:before {
  content: "\eaf1";
}
.icon-chevron-up1:before {
  content: "\eaf2";
}
.icon-chevron-right1:before {
  content: "\eaef";
}
.icon-caret-left:before {
  content: "\f0d9";
}
.icon-caret-down:before {
  content: "\f0d7";
}
.icon-caret-up:before {
  content: "\f0d8";
}
.icon-caret-right:before {
  content: "\f0da";
}
.icon-play3:before {
  content: "\e923";
}
.icon-tv-circle:before {
  content: "\e941";
}
.icon-wifi-circle:before {
  content: "\e93e";
}
.icon-checkmark-small:before {
  content: "\e938";
}
.icon-checkmark2:before {
  content: "\e951";
}
.icon-arrow-up-down2:before {
  content: "\e956";
}
.icon-location-marker:before {
  content: "\e934";
}
.icon-location-marker-2:before {
  content: "\e91f";
}
.icon-shield-check:before {
  content: "\e92d";
}
.icon-tech-support:before {
  content: "\e953";
}
.icon-gift-card:before {
  content: "\e952";
}
.icon-price-tag-v2:before {
  content: "\e950";
}
.icon-info-outline:before {
  content: "\e94e";
}
.icon-price-lock:before {
  content: "\e94c";
}
.icon-wrench:before {
  content: "\e94b";
}
.icon-monitor:before {
  content: "\e948";
}
.icon-padlock-unlocked:before {
  content: "\e949";
}
.icon-modern-game-controller:before {
  content: "\e947";
}
.icon-cables:before {
  content: "\e920";
}
.icon-global-wifi:before {
  content: "\e921";
}
.icon-speed-gauge:before {
  content: "\e924";
}
.icon-whole-home-wifi:before {
  content: "\e925";
}
.icon-wifi-v3:before {
  content: "\e946";
}
.icon-cloud-upload-red:before {
  content: "\e91e";
}
.icon-upload-download-cloud:before {
  content: "\e94d";
}
.icon-user:before {
  content: "\e90a";
}
.icon-users:before {
  content: "\e91b";
}
.icon-user-v2:before {
  content: "\e91c";
}
.icon-invoice:before {
  content: "\e94f";
}
.icon-money-sign:before {
  content: "\e926";
}
.icon-price-tag:before {
  content: "\e92a";
}
.icon-stopwatch:before {
  content: "\e942";
}
.icon-clock:before {
  content: "\e927";
}
.icon-circled-check-mark:before {
  content: "\e91d";
}
.icon-star-v3:before {
  content: "\e928";
}
.icon-calendar:before {
  content: "\e929";
}
.icon-phone-outline:before {
  content: "\e943";
}
.icon-magnifying-glass:before {
  content: "\e935";
}
.icon-shopping-cart:before {
  content: "\e936";
}
.icon-cart:before {
  content: "\e901";
}
.icon-youtube:before {
  content: "\e90b";
}
.icon-play1:before {
  content: "\e916";
}
.icon-play2:before {
  content: "\e932";
}
.icon-heart:before {
  content: "\e933";
}
.icon-star:before {
  content: "\e92b";
}
.icon-shield:before {
  content: "\e918";
}
.icon-youtube-v2:before {
  content: "\e954";
}
.icon-wifi:before {
  content: "\e92c";
}
.icon-close:before {
  content: "\e90d";
}
.icon-minus:before {
  content: "\e902";
}
.icon-plus:before {
  content: "\e90c";
}
.icon-book:before {
  content: "\e900";
}
.icon-exclamation:before {
  content: "\e903";
}
.icon-facebook:before {
  content: "\e904";
}
.icon-info:before {
  content: "\e905";
}
.icon-linkedin:before {
  content: "\e906";
}
.icon-phone:before {
  content: "\e907";
}
.icon-search:before {
  content: "\e908";
}
.icon-twitter:before {
  content: "\e909";
}
.icon-ban:before {
  content: "\e90e";
}
.icon-caution:before {
  content: "\e90f";
}
.icon-game-controller:before {
  content: "\e910";
}
.icon-gem:before {
  content: "\e911";
}
.icon-home:before {
  content: "\e912";
}
.icon-key:before {
  content: "\e913";
}
.icon-lock:before {
  content: "\e915";
}
.icon-repeat:before {
  content: "\e917";
}
.icon-thumbs-up:before {
  content: "\e919";
}
.icon-tv:before {
  content: "\e91a";
}
.icon-laptop:before {
  content: "\e914";
}
.icon-credit-card:before {
  content: "\e92e";
}
.icon-stacked-hamburglar:before {
  content: "\e94a";
}
.icon-laptop-v2:before {
  content: "\e922";
}
.icon-life-ring:before {
  content: "\e92f";
}
.icon-locked:before {
  content: "\e930";
}
.icon-money:before {
  content: "\e931";
}
.icon-up-trend:before {
  content: "\e937";
}
.icon-twitter-x:before {
  content: "\e95a";
}
