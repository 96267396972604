/* -----------------------------------------------------------------------------
 *
 * Components: Modals
 *
 * ----------------------------------------------------------------------------- */

.modal {
  display: none;
  position: relative;
  z-index: 999;
}

.modal.is-open {
  display: block;
}

.modal__overlay {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background: color-mod( var( --color-black ) a( 0.6 ) );
}

.modal__container {
  box-sizing: border-box;
  max-height: 100vh;
  padding: 30px;
  overflow-y: auto;
  border-radius: 4px;
  background-color: var( --color-white );
}

.modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  color: var( --color-midnight-express );
  font-size: rem( 20px );
  font-weight: 600;
}

.modal__close {
  border: 0;
  background: transparent;
  cursor: pointer;

  span {
    pointer-events: none;
  }
}

.modal__close::before {
  @mixin icomoon;

  content: var( --icon-close );
}

.modal__close-bold {
  border: 0;
  background: transparent;
  cursor: pointer;

  span {
    pointer-events: none;
  }
}

.modal__close-bold::before {
  @mixin icomoon;

  content: var( --icon-close-bold );
}

.modal__content {
  margin-top: 24px;
  margin-bottom: 24px;
  color: color-mod( var( --color-black ) a( 0.8 ) );
}

/* -----------------------------------------------------------------------------
 * Animation Styles
 * ----------------------------------------------------------------------------- */

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY( 15% );
  }

  to {
    transform: translateY( 0 );
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY( 0 );
  }

  to {
    transform: translateY( -10% );
  }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[ aria-hidden='false' ] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier( 0, 0, 0.2, 1 );
}

.micromodal-slide[ aria-hidden='false' ] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier( 0, 0, 0.2, 1 );
}

.micromodal-slide[ aria-hidden='true' ] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier( 0, 0, 0.2, 1 );
}

.micromodal-slide[ aria-hidden='true' ] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier( 0, 0, 0.2, 1 );
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}
