/* -----------------------------------------------------------------------------
 * Display
 * ----------------------------------------------------------------------------- */


.display-none {
  display: none !important;
}

@media (--small-max) {
  .display-none-xs {
    display: none !important;
  }
}

@media (--small) and (--medium-max) {
  .display-none-sm {
    display: none !important;
  }
}

@media (--medium) and (--large-max) {
  .display-none-md {
    display: none !important;
  }
}

@media (--large) and (--xlarge-max) {
  .display-none-lg {
    display: none !important;
  }
}

@media (--xlarge) {
  .display-none-xl {
    display: none !important;
  }
}
