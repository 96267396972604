/* -----------------------------------------------------------------------------
 *
 * Components: Icons
 *
 * ----------------------------------------------------------------------------- */

[ class^='icon-' ],
[ class*=' icon-' ] {
  @mixin icomoon;

  letter-spacing: 0;
  text-decoration: none;
}
