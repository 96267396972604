/* -----------------------------------------------------------------------------
 *
 * Typography: Links.
 *
 * ----------------------------------------------------------------------------- */

a {
  transition: color 0.3s;
  color: var( --link-color );
  text-decoration: none;

  &:hover,
  &:focus {
    transition: all 0.3s;
    color: var( --link-hover-color );
    text-decoration: underline;
  }

  &::before,
  &::after {
    transition: all 0.3s;

    &:hover,
    &:focus {
      transition: all 0.3s;
    }
  }

  &[ href^='tel' ] {
    color: inherit;
    text-decoration: none;
  }
}

a > span,
a > em {
  pointer-events: none;
}
