/* -----------------------------------------------------------------------------
 *
 * Regions: Site
 *
 * ----------------------------------------------------------------------------- */

.site {
  position: relative;
  min-width: 320px;
}

/* -----------------------------------------------------------------------------
 * Site Error
 * ----------------------------------------------------------------------------- */
.site-error {
  margin: 40px 0;
  padding: 60px 0;
  border-bottom: 1px solid var( --color-ghost-gray );
}
